<template>
  <v-app>
      <Repetidor/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Repetidor from '@/components/Repetidor.vue'

export default {
  name: 'RepetidorView',
  components: {
    Repetidor
  }
}
</script>

